<template>
  <div v-show="!isLoading" class="transition-all duration-500">
    <Common no-top-margin left-menu><slot /></Common>
  </div>
</template>

<script setup>
import Common from '@/layouts/Common.vue';

const nuxtApp = useNuxtApp();
const isLoading = ref(true);
nuxtApp.hook('page:start', () => {
  isLoading.value = true;
});
nuxtApp.hook('page:finish', () => {
  isLoading.value = false;
});
</script>

<style lang="scss"></style>
